import { render, staticRenderFns } from "./record-table.vue?vue&type=template&id=1e753765&"
import script from "./record-table.vue?vue&type=script&lang=ts&setup=true&"
export * from "./record-table.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./record-table.vue?vue&type=style&index=0&id=1e753765&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports